import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Collection() {
    const [materials, setMaterials] = useState([]);

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    useEffect(() => {
        const loadMaterials = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/gallery?limit=4`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setMaterials(resp.data);
        };

        loadMaterials();
    }, []);

    return (
        <Fragment>
            <Box backgroundColor={"#202020"}>
                <Grid
                    container
                    marginY={6}
                    gap={4}
                    display={"flex"}
                    paddingX={8}
                >
                    <Grid
                        xs={12}
                        md={12}
                        marginTop={4}
                        color="#FFFFFF"
                        borderBottom={1}
                    >
                        <Typography
                            color="#FFFFFF"
                            width={"fit-content"}
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="800"
                            fontSize="20px"
                            lineHeight="23px"
                        >
                            Galeria de fotos
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography
                            color="#FFFFFF"
                            width={"fit-content"}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21px"
                        >
                            Acervo de fotos e lutas
                        </Typography>
                    </Grid>

                    {materials.map((item) => (
                        <Grid
                            xs={12}
                            md={2.7}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            key={item._id}
                        >
                            <Link
                                aria-label="noticias"
                                to={`/galeria/fotos?link=${item.link}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image={item.photos && item.photos.length !== 0
                                        ? item.photos[0].url
                                        : item.ID.toString().length > 5
                                            ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpeg`
                                                : `https://galeria-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpg`}
                                    alt="collection image"
                                />
                                <Typography
                                    color="#EAECF0"
                                    marginY={1}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="12px"
                                    lineHeight="14px"
                                >
                                    {formatDate(item.incData)}
                                </Typography>
                                <Typography
                                    color="#EAECF0"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="16px"
                                >
                                    {item.titulo}
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    xs={12}
                    md={12}
                    padding={"16px 0px"}
                    color="#FFFFFF"
                    borderBottom={1}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Link
                        aria-label="galeria"
                        to={`/galeria`}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Button
                            size="small"
                            style={{
                                backgroundColor: "#FFFFFF",
                                borderRadius: 100,
                                color: "#202020",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}
                        >
                            VEJA TODAS AS FOTOS
                        </Button>
                    </Link>
                </Grid>
            </Box>
        </Fragment>
    );
}

export default Collection;
