import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import { Box, Button, Typography } from "@mui/material";

const Wellhub = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const iframe = `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaYOIJRP2kHBeYpUkLM26loMl5Ss2rWgZukHigWoxVzioSQA/viewform?embedded=true" width="${isMobile ? 340 : 640}" height="1727" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>`

    return (
        <div>
            <Header isMobile={isMobile} />
            <Box
                marginY={6}
                gap={2}
                display={"flex"}
                flexDirection={`column`}
                paddingX={isMobile ? 4 : 8}
                style={{ maxWidth: "95%" }}
                marginBottom={32}>

                <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 16 }}>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16
                    }}>

                        <img
                            src="../files/gympass.png"
                            style={{ width: isMobile ? "80vw" : '35vw' }}
                            alt="logo da tvt"
                        />
                         <Typography
                            fontFamily="Inter"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="22px"
                            color="#202020"
                            marginTop={1}
                            whiteSpace={"pre-line"}
                            width={isMobile ? "80vw" : '35vw'}
                        >
PARCERIA COM A PLATAFORMA GYMPASS/WELLHUB
                        </Typography>
                        <Typography
                            fontFamily="Inter"
                            fontStyle="normal"
                            fontWeight="500"
                            fontSize="16px"
                            lineHeight="22px"
                            color="#202020"
                            marginTop={1}
                            whiteSpace={"pre-line"}
                            width={isMobile ? "80vw" : '35vw'}
                        >
                            {`A plataforma Wellhub/Gympass vai conectar as(os) filiadas(os) do Sindsep à melhor rede de parceiros para atividades físicas (academias, pilates, natação, crossfit, etc.), mindfulness, terapia, nutrição e qualidade do sono, com uma única assinatura que custa menos e oferece muito mais.

O sindicato sabe que cuidar do corpo e da mente é uma necessidade humana fundamental. Defendemos que isso seja também parte das políticas públicas. Mas em nossa luta por melhorar a vida dos servidores e servidoras, conquistamos mais esse benefício para nossos associados. Se associe ao Sindsep!

Após concluir o formulário o SINDSEP irá checar as informações para verificar se você está apto para acessar a plataforma. Após checagem a validação na plataforma Wellhub pode levar de 1 a 15 dias uteis. Você receberá um e-mail do Wellhub, deverá baixar o aplicativo Wellhub e concluir o cadastro, bem como a seleção do plano diretamente com a Wellhub.

Faça já seu pré cadastro. Lançamento oficial dia 14 de abril de 2025.`}
                        </Typography>
                        <img
                            src="../files/wellhub.png"
                            style={{ width: isMobile ? "80vw" : '35vw' }}
                            alt="logo da tvt"
                        />
                    </Box>
                    <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
                </Box>
            </Box>
            <Footer />
            <SiteMap />
        </div>
    );
};

export default Wellhub;
