const Weight = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="36"
		height="36" viewBox="0 0 86 85" xmlSpace="preserve">
			<g>
				<path fill="#202020" d="M46.4,45.9l1.1,1.1L46.4,45.9z M20.5,71.8l-1.1-1.1l0,0L20.5,71.8z M72.3,20l-1.1-1.1L72.3,20z M65.5,13.2
		l-1.1-1.1L65.5,13.2z M39.6,39.1l1.1,1.1L39.6,39.1z M13.7,65l-1.1-1.1L13.7,65z M13.7,66.9L12.6,68L13.7,66.9z M18.6,71.8l1.1-1.1
		L18.6,71.8z M45.2,44.7L19.3,70.6l2.2,2.2L47.5,47L45.2,44.7z M47.5,47l25.9-25.9l-2.2-2.2L45.2,44.7L47.5,47z M73.4,21.1
		c1.1-1.1,1.1-3,0-4.1l-2.2,2.2c-0.1-0.1-0.1-0.3,0-0.3L73.4,21.1z M73.4,17l-4.8-4.8l-2.2,2.2l4.8,4.8L73.4,17z M68.5,12.1
		c-1.1-1.1-3-1.1-4.1,0l2.2,2.2c-0.1,0.1-0.3,0.1-0.3,0L68.5,12.1z M64.4,12.1L38.5,38l2.2,2.2l25.9-25.9L64.4,12.1z M14.9,66.2
		l25.9-25.9L38.5,38L12.6,63.9L14.9,66.2z M14.9,65.8c0.1,0.1,0.1,0.3,0,0.3l-2.2-2.2c-1.1,1.1-1.1,3,0,4.1L14.9,65.8z M19.7,70.6
		l-4.8-4.8L12.6,68l4.8,4.8L19.7,70.6z M19.3,70.6c0.1-0.1,0.3-0.1,0.3,0l-2.2,2.2c1.1,1.1,3,1.1,4.1,0L19.3,70.6z"/>
			</g>
			<g>
				<path fill="#606060" d="M39.4,14.7l-3.5,3.5c-1,1-1,2.7,0,3.8l27.5,27.5c1,1,2.7,1,3.8,0l3.5-3.5c1-1,1-2.7,0-3.8L43.2,14.7
		C42.2,13.7,40.5,13.7,39.4,14.7z"/>
				<path fill="#202020" d="M65.4,51.9c-1.1,0-2.2-0.4-3-1.2L34.8,23.1c-1.7-1.7-1.7-4.3,0-6l3.5-3.5c1.7-1.7,4.3-1.7,6,0l27.5,27.5
		c1.7,1.7,1.7,4.3,0,6l-3.5,3.5C67.5,51.5,66.5,51.9,65.4,51.9z M41.3,15.5c-0.3,0-0.6,0.1-0.8,0.3l-3.5,3.5c-0.4,0.4-0.4,1.1,0,1.5
		l27.5,27.5c0.4,0.4,1.1,0.4,1.5,0l3.5-3.5c0.4-0.4,0.4-1.1,0-1.5L42.1,15.9C41.9,15.7,41.6,15.5,41.3,15.5z"/>
			</g>
			<g>
				<path fill="#606060" d="M51.2,11.9l-3.5,3.5c-1,1-1,2.7,0,3.8l18.6,18.6c1,1,2.7,1,3.8,0l3.5-3.5c1-1,1-2.7,0-3.8L54.9,11.9
		C53.9,10.9,52.2,10.9,51.2,11.9z"/>
				<path fill="#202020" d="M68.2,40.2c-1.1,0-2.2-0.4-3-1.2L46.6,20.3c-1.7-1.7-1.7-4.3,0-6l3.5-3.5c1.7-1.7,4.3-1.7,6,0l18.6,18.6
		c0.8,0.8,1.2,1.9,1.2,3s-0.4,2.2-1.2,3l-3.5,3.5C70.4,39.7,69.3,40.2,68.2,40.2z M52.3,13.1l-3.5,3.5c-0.4,0.4-0.4,1.1,0,1.5
		l18.6,18.6c0.4,0.4,1.1,0.4,1.5,0l3.5-3.5c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8L53.8,13.1C53.4,12.6,52.7,12.6,52.3,13.1
		L52.3,13.1z"/>
			</g>
			<g>
				<path fill="#606060" d="M15.2,38.9l3.5-3.5c1-1,2.7-1,3.8,0L50.1,63c1,1,1,2.7,0,3.8l-3.5,3.5c-1,1-2.7,1-3.8,0L15.2,42.7
		C14.2,41.7,14.2,40,15.2,38.9z"/>
				<path fill="#202020" d="M44.7,72.6c-1.1,0-2.2-0.4-3-1.2L14.1,43.8c-1.7-1.7-1.7-4.3,0-6l3.5-3.5c1.7-1.7,4.3-1.7,6,0l27.5,27.5
		c1.7,1.7,1.7,4.3,0,6l-3.5,3.5C46.8,72.2,45.8,72.6,44.7,72.6z M20.6,36.2c-0.3,0-0.6,0.1-0.8,0.3l-3.5,3.5
		c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8l27.5,27.5c0.4,0.4,1.1,0.4,1.5,0l3.5-3.5c0.4-0.4,0.4-1.1,0-1.5L21.4,36.6
		C21.2,36.3,20.9,36.2,20.6,36.2z"/>
			</g>
			<g>
				<path fill="#606060" d="M12.4,50.7l3.5-3.5c1-1,2.7-1,3.8,0l18.6,18.6c1,1,1,2.7,0,3.8l-3.5,3.5c-1,1-2.7,1-3.8,0L12.4,54.4
		C11.4,53.4,11.4,51.7,12.4,50.7z"/>
				<path fill="#202020" d="M32.9,75.4c-1.1,0-2.2-0.4-3-1.2L11.3,55.6c-0.8-0.8-1.2-1.9-1.2-3c0-1.1,0.4-2.2,1.2-3l3.5-3.5
		c1.7-1.7,4.3-1.7,6,0l18.6,18.6c0.8,0.8,1.2,1.9,1.2,3c0,1.1-0.4,2.2-1.2,3l-3.5,3.5C35.1,75,34.1,75.4,32.9,75.4z M17.8,48
		c-0.3,0-0.6,0.1-0.8,0.3l-3.5,3.5c-0.4,0.4-0.4,1.1,0,1.5l18.6,18.6c0.4,0.4,1.1,0.4,1.5,0l3.5-3.5c0.2-0.2,0.3-0.5,0.3-0.8
		c0-0.3-0.1-0.6-0.3-0.8L18.6,48.3C18.4,48.1,18.1,48,17.8,48z"/>
			</g>
		</svg>
	);
};

export default Weight;