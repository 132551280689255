import { Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import axios from "axios";
import PhotosItem from "./commons/PhotosItem";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

function AllPhotos({ isMobile }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        setSearchParams({ page: Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1 });
        loadPage(Number(searchParams.get("page")))
    }, [searchParams]);

    async function loadPage(number) {
        setSearchParams({ page: number });
        if (number <= 0) {
            setSearchParams({ page: 1 });    
            number = 1
        }

        const loadPhotos = async (number) => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/gallery?page=${Number(number) - 1}`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setPhotos(resp.data);
        };

        loadPhotos(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }

    return (
        <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Galeria
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid container marginY={6} gap={4} display={"flex"}>
                        {photos.map((item) => (
                            <Grid
                                xs={12}
                                md={2.4}
                                xl={2.6}
                                display={"flex"}
                                padding={"0px 10px"}
                                key={item._id}
                            >
                                <PhotosItem
                                    image={
                                        item.photos && item.photos.length !== 0
                                            ? item.photos[0].url
                                            : item.ID.toString().length > 5
                                                ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpeg`
                                                : `https://galeria-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpg`
                                    }
                                    date={item.incData}
                                    description={item.titulo}
                                    link={item.link}
                                    categorie={item.categorie}
                                    categorieColor={item.categorieColor}
                                    isMobile={isMobile}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Pagination
                    color="warning"
                    variant="outlined" count={63} page={Number(searchParams.get("page"))} onChange={(e, number) => loadPage(number)}
                    renderItem={(item) => (
                        <PaginationItem
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}

                            slots={{ previous: ArrowBackOutlined, next: ArrowForwardOutlined }}
                            {...item}
                        />)}
                />
            </Grid>
        </Fragment>
    );
}

export default AllPhotos;
