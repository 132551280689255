import Assessoria from "./Assessoria";
import Home from "./Home";
import News from "./News";
import Calculator from "./Calculator";
import Materials from "./Materials";
import Services from "./Services";
import Congress from "./Congress";
import Photos from "./Photos";
import Videos from "./Videos";
import NewsItemId from "./NewsItemId";
import PhotoItemId from "./PhotoItemId";
import MaterialItemId from "./MaterialItemId";
import Sindicate from "./Sindicate";
import Minuta from "./Minuta";
import Campaing from "./Campaing";
import SignUp from "./SignUp";
import FinishAccount from "./FinishAccount";
import ServerArea from "./ServerArea";
import EditData from "./EditData";
import AdvancedSearch from "./AdvancedSearch";
import Politica from "./Politica";
import Wellhub from "./Wellhub";

const routes = [
    { path: "/", component: <Home /> },
    { path: "/13oCongresso", component: <Congress /> },
    { path: "/noticias", component: <News legal={false} /> },
    { path: "/noticia", component: <NewsItemId legal={false} /> },
    { path: "/juridico", component: <News legal={true} /> },
    { path: "/assessoria", component: <Assessoria /> },
    { path: "/calculadora", component: <Calculator /> },
    { path: "/materiais", component: <Materials /> },
    { path: "/materiais/item", component: <MaterialItemId /> },
    { path: "/servicos", component: <Services /> },
    { path: "/galeria", component: <Photos /> },
    { path: "/galeria/fotos", component: <PhotoItemId /> },
    { path: "/sindicalizacao", component: <Sindicate /> },
    { path: "/SugestaoParaMinuta", component: <Minuta /> },
    { path: "/CampanhaSalarial", component: <Campaing /> },
    { path: "/videos", component: <Videos /> },
    { path: "/criar-conta", component: <SignUp /> },
    { path: "/finalizar-conta", component: <FinishAccount /> },
    { path: "/area-do-servidor", component: <ServerArea /> },
    { path: "/atualize-seu-cadastro", component: <EditData /> },
    { path: "/busca-avancada", component: <AdvancedSearch /> },
    { path: "/institucional/politica-de-privacidade", component: <Politica /> },
    { path: "/wellhub", component: <Wellhub /> },
];

export default routes;
