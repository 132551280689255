import React, { Fragment, useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Logo from "../assets/logo";
import LogoMobile from "../assets/logomobile";
import ServiceArea from "../assets/servicearea";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import Legal from "../assets/legal";
import Document from "../assets/document";
import MenuComponent from "./MenuComponent";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import News from "../assets/news";
import Library from "../assets/library";
import Benefits from "../assets/benefits";
import TalkToUs from "../assets/talktous";
import Accessible from "../assets/accessible";
import axios from "axios";

import ListSubheader from '@mui/material/ListSubheader';
import Weight from "../assets/weight.";

const categories = [
    { id: 10, name: "Boletins" },
    { id: 11, name: "Revistas" },
    { id: 12, name: "Legislação" },
    { id: 14, name: "Documentos" },
    { id: 15, name: "Faixas" },
    { id: 16, name: "Jornal" },
    { id: 17, name: "Convocatória" },
    { id: 18, name: "Carta Aberta" },
    { id: 19, name: "Cards" },
    { id: 20, name: "Cartaz" },
    { id: 21, name: "Pirulitos de Manifestação" },
];

function Header({ isMobile }) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newsCategorie, setNewsCategorie] = useState("");

    const [newsCategories, setNewsCategories] = useState([]);
    const [text, setText] = useState("");

    useEffect(() => {
        if ((window.location.pathname.includes("/materiais") && !window.location.pathname.includes("item"))
            || window.location.pathname.includes("/noticias")
            || window.location.pathname.includes("/galeria")
            || window.location.pathname.includes("/busca-avancada")
        ) {
            setSearchParams({
                page: Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1,
                search: searchParams.get("search") ? searchParams.get("search") : "",
                categorie: searchParams.get("categorie") ? searchParams.get("categorie") : 0,
            })             
        }

        if ((text && text !== "") || (newsCategorie && newsCategorie !== "0")) {
            navigate(`/busca-avancada?search=${text}&categorie=${newsCategorie}`);
        }

        const loadCategories = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/categories`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            let newsCat = [];

            newsCat = newsCat.concat(resp.data.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }));

            setNewsCategories(newsCat);
        };

        loadCategories();

    }, [text, newsCategorie]);

    return (
        <Fragment>
            <Toolbar
                sx={{ borderBottom: 1, borderColor: "divider", padding: 2 }}
            >
                <Link to="/" aria-label="link para home">
                    {!isMobile && <Logo />}
                    {isMobile && <LogoMobile />}
                </Link>
                {!isMobile && (
                    <Box
                        component="h2"
                        color="inherit"
                        align="center"
                        display={"flex"}
                        justifyContent={"center"}
                        gap={3}
                        noWrap={true}
                        sx={{ flex: 1 }}
                    >
                        <Link
                            to="/servicos"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Benefits />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Benefícios
                                </Typography>
                            </Box>
                        </Link>

                        <Link
                            to="/materiais"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Library />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Biblioteca de Materiais
                                </Typography>
                            </Box>
                        </Link>
                        <Link
                            to="/juridico"
                            aria-label="link para juridico"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Legal />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Consulta Jurídica
                                </Typography>
                            </Box>
                        </Link>
                        <Link
                            to="/noticias"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <News />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Notícias
                                </Typography>
                            </Box>
                        </Link>
                        <li><a
                            href="https://www.areaprivada.prefeitura.sp.gov.br/PortalPMSP/PortalPMSP/Portal/PMSPprt001.tp"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para prefeitura, no holerite eletronico"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={60}>
                                <Document />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Holerite Eletrônico
                                </Typography>
                            </Box>
                        </a></li>
                        <Link
                            to="/galeria"
                            aria-label="link para galeria"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <ServiceArea />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Galeria de Fotos
                                </Typography>
                            </Box>
                        </Link>
                        <li><a
                            href="https://www.escolasindsep.com.br"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para o escolasindsep"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={90}>
                                <TalkToUs />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                    whiteSpace={"pre-line"}
                                >
                                    Escola de formação
                                </Typography>
                            </Box>
                        </a></li>
                        
                        <Link
                            to="/wellhub"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Weight />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    WellHub Gympass
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                )}
                {!isMobile && (
                    <li><a
                        href="https://sindicalizacao.sindsep-sp.org.br"
                        target={"_blank"}
                        rel="noreferrer"
                        aria-label="ir para pagina de sindicalização online"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Button
                            size="small"
                            style={{
                                backgroundColor: "#EB2227",
                                borderRadius: 100,
                                color: "#FFFFFF",
                                padding: "9px 19px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}
                        >
                            SINDICALIZAÇÃO ON-LINE
                        </Button>
                    </a></li>
                )}

                {isMobile && (
                    <li><a
                        href="https://sindicalizacao.sindsep-sp.org.br"
                        target={"_blank"}
                        rel="noreferrer"
                        aria-label="redirecionamento para sindicalização online"
                        style={{
                            textDecoration: "none",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            size="small"
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "10px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "12px",
                                lineHeight: "14px",
                                marginLeft: 6,
                                maxWidth: "30%",
                            }}
                        >
                            SINDICALIZAÇÃO ON-LINE
                        </Button>
                    </a></li>
                )}
            </Toolbar>
            {isMobile && (
                <Box>
                    <Box
                        component="h2"
                        color="inherit"
                        align="center"
                        display={"flex"}
                        justifyContent={"center"}
                        gap={2}
                        noWrap={true}
                        paddingX={4}
                    >
                        <Link
                            to="/servicos"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Benefits />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Benefícios e Convênios
                                </Typography>
                            </Box>
                        </Link>
                        <Link
                            to="/materiais"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Library />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Biblioteca de Materiais
                                </Typography>
                            </Box>
                        </Link>
                        <Link
                            to="/juridico"
                            aria-label="link para juridico"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={70}>
                                <Legal />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Consulta Jurídica
                                </Typography>
                            </Box>
                        </Link>
                        <li><a
                            href="https://api.whatsapp.com/send?phone=5511970255497"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para o whatsapp"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={70}>
                                <TalkToUs />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                    whiteSpace={"pre-line"}
                                >
                                    {"Fale\n Conosco"}
                                </Typography>
                            </Box>
                        </a></li>
                    </Box>
                    <Box
                        component="h2"
                        color="inherit"
                        align="center"
                        display={"flex"}
                        justifyContent={"center"}
                        gap={3}
                        noWrap={true}
                        sx={{ flex: 1 }}
                    >
                        <Link
                            to="/noticias"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <News />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Notícias
                                </Typography>
                            </Box>
                        </Link>
                        <li><a
                            href="https://www.areaprivada.prefeitura.sp.gov.br/PortalPMSP/PortalPMSP/Portal/PMSPprt001.tp"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para prefeitura, no holerite eletronico"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={60}>
                                <Document />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Holerite Eletrônico
                                </Typography>
                            </Box>
                        </a></li>
                        <li><a
                            href="https://diariooficial.prefeitura.sp.gov.br/md_epubli_controlador.php?acao=inicio"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para prefeitura, no diário oficial"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <ServiceArea />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    Diário Oficial da Cidade
                                </Typography>
                            </Box>
                        </a></li>
                        
                        <Link
                            to="/wellhub"
                            aria-label="link para noticias"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Box width={80}>
                                <Weight />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="14px"
                                    color="#010101"
                                >
                                    WellHub Gympass
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                </Box>
            )}
            <Toolbar
                component="nav"
                variant="dense"
                sx={{ justifyContent: "space-between", overflowX: "auto" }}
            >
                <MenuComponent />
                {!isMobile && (
                    <Box
                        component="h2"
                        align="center"
                        display={"flex"}
                        justifyContent="center"
                        sx={{ flex: 1 }}
                    >
                        <Box width={"50vw"} style={{ display: "flex" }}>
                            <TextField
                                id="outlined-basic"
                                label={
                                    window.location.pathname.includes(
                                        "materiais"
                                    )
                                        ? "Buscar Materiais"
                                        : "Buscar Notícias"
                                }
                                variant="outlined"
                                name="busca"
                                aria-label="busca"
                                fullWidth
                                size="small"
                                defaultValue={searchParams.get("search") || ""}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setText(e.target.value);
                                    }
                                }}
                            />
                        </Box>
                        <FormControl size="small" style={{ marginLeft: 8 }}>
                            <InputLabel id="demo-simple-select-label">
                                Categorias
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={searchParams.get("categorie") ? searchParams.get("categorie") : 0}
                                label="Categorias"
                                onChange={(e) =>
                                    setNewsCategorie(e.target.value)
                                }
                            >
                                <MenuItem key={-1} value={0}>
                                    TODAS AS CATEGORIAS
                                </MenuItem>
                                <ListSubheader>Notícias</ListSubheader>
                                {newsCategories.map((cat, count) => (
                                    <MenuItem key={count} value={cat.name}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                                <ListSubheader>Materiais</ListSubheader>
                                {categories.map((cat, count) => (
                                    <MenuItem key={count} value={cat.id}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {isMobile && (
                    <Box
                        component="h2"
                        align="center"
                        display={"flex"}
                        justifyContent="center"
                        sx={{ flex: 1 }}
                    ></Box>
                )}
                <li><a
                    href="https://www.facebook.com/sindsep"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do facebook"
                    style={{ marginRight: 4 }}
                >
                    <Facebook style={{ color: "#EB2227" }} />
                </a></li>
                <li><a
                    href="https://www.instagram.com/sindsepsp"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do instagram"
                    style={{ marginRight: 4 }}
                >
                    <Instagram style={{ color: "#EB2227" }} />
                </a></li>
                <li><a
                    href="https://twitter.com/sindsep"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do twitter"
                    style={{ marginRight: 4 }}
                >
                    <Twitter style={{ color: "#EB2227" }} />
                </a></li>

                <Box
                    marginLeft={2}
                    backgroundColor="#004F8F"
                    borderRadius={999}
                    width="20px"
                    height="20px"
                >
                    <Accessible />
                </Box>
                {!isMobile && (
                    <Typography
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="13px"
                        color="#004F8F"
                        marginX={1}
                        whiteSpace={"pre-line"}
                    >
                        {"Site\n acessível"}
                    </Typography>
                )}
            </Toolbar>
            {isMobile && (
                <Toolbar
                    component="nav"
                    variant="dense"
                    sx={{ justifyContent: "space-between", overflowX: "auto" }}
                >
                    <Box
                        component="h2"
                        align="center"
                        display={"flex"}
                        justifyContent="center"
                        sx={{ flex: 1 }}
                    >
                        <Box width={"80vw"}>
                            <TextField
                                id="outlined-basic"
                                label="Busca"
                                variant="outlined"
                                name="busca"
                                aria-label="busca"
                                fullWidth
                                size="small"
                                defaultValue={searchParams.get("search") || ""}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        setText(e.target.value);
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Toolbar>
            )}
        </Fragment>
    );
}

export default Header;
